const S3BucketUrl = 'https://mursion-static-assets.s3.us-west-2.amazonaws.com';
const prodHostName = 'portal.mursion.com';
const stageHostName = 'staging.portal.mursion.com';

export const getS3BucketURL = (folderName: string, fileName: string) => {
  if (prodHostName === window.location.hostname) {
    return `${S3BucketUrl}/${folderName}/production/${fileName}`;
  }

  if (stageHostName === window.location.hostname) {
    return `${S3BucketUrl}/${folderName}/staging/${fileName}`;
  }

  return `${S3BucketUrl}/${folderName}/other-environment/${fileName}`;
};