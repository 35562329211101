exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"colors.css","sourceRoot":""}]);

// exports
exports.locals = {
	"c_purple": "#5400BF",
	"c_purple_light": "#6E4AFF",
	"c_red": "#FF615C",
	"c_red_light": "#FFBFC9",
	"c_yellow": "#FFD670",
	"c_yellow_light": "#FFE8BA",
	"c_black": "#424242",
	"c_pink": "#A32EAD",
	"c_pink_mid": "#BF70BF",
	"c_pink_light": "#FFC9FF",
	"c_tan": "#AD998F",
	"c_tan_mid": "#D9C3B9",
	"c_tan_light": "#EDE1DF",
	"c_tan_backdrop": "#F1EFEE",
	"c_grey_deep": "#05243D",
	"c_grey_mid": "#4A6173",
	"c_grey_light": "#F5F5F5",
	"c_green": "#93D2AB"
};