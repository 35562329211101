
export default {
  ASSIGN_PASSWORD: '/join/:invitationToken',
  CALENDAR: '/calendar',
  CALENDAR_EVENT: '/calendar/event/:eventType/:eventId',
  CLIENTS: '/clients',
  COMPANY: '/company',
  COMPANIES: '/companies',
  DASHBOARD: '/dashboard',
  DOWNLOADS: '/downloads',
  EDIT_PROFILE: '/edit-profile',
  FILL_PROFILE: '/fill-profile',
  HOME: '/',
  LOGIN: '/login',
  PASSWORD_RESTORE: '/password-restore',
  PROJECTS: '/projects',
  SESSIONS: '/sessions',
  SESSION_REPORT: '/session-report',
  SUCCESS: '/success',
  REQUESTS: '/requests',
  TRAININGS: '/trainings',
  REPORTS: '/reports',
  TEAMS: '/teams',
  TUTORIAL: '/tutorial',
  TUTORIAL_NEW: '/tutorial-new',
  OPEN: '/open/:sessionId',
  MONITORING: '/monitoring',
  SESSION_MONITROING: '/monitoring/:sessionId',
  USERS: '/users',
  TECH_RESTRICTIONS: '/tech-restrictions',
  EULA: '/eula',
  OAUTH: '/oauth2',
  ERROR: '/error',
  SSO_LINK: '/sso-link',
  ASSET_SYNC: '/asset-sync',
  SSO: '/sso-schemas',
  SETTINGS: '/settings',
  SIMULATION_SCHEDULING: '/simulation-scheduling',
  RESCHEDULING: '/rescheduling',
  PUBLIC_SESSION: '/external/session/:sessionId/join',
  EMAIL_VERIFICATION: '/email/verify/:token',
  MARK_ATTENDANCE: '/mark-attendance',
  ART_DESIGNER: '/art-designer',
  ART_DESIGNER_PORTAL: '/art-designer-portal/index.html',
  CONTRACTS: '/contracts',
  SURVEY: '/survey/:pathName/:pathId',
  COMPLETED_SIMULATIONS:'/completed-simulations',
  SURVEY_RESPONSE: '/survey-response',
  INSTRUCTOR_MAPPING: '/instructor-mapping/:contextId',
  DASHBOARD_REPORT: '/dashboard-report',
  SCENARIOS: '/scenarios',
  CHANGE_PASSWORD: '/change-password',
  CHANGE_PASSWORD_SUCCESS: '/change-password-success',
  LEARNER_SESSIONS_REPORT: '/learner-session-report',
  SIM_VIEW:'/dashboard/supply_management/sim_view',
  SCHEDULING_VIEW:'/dashboard/supply_management/scheduling_view',
  SIMULATION_DETAILS: '/simulations/detail',
  JOIN_NOW: '/learner-join-now',
  SIM_REQUESTS_PAGE: '/dashboard/requests',
  ONBOARDING: '/learner-onboarding',
  SCENARIO_BANK: '/scenario_bank',
  LEARNER_MATERIALS: '/learner-materials',
  NEXT_GEN_LEARNER_SESSION_REPORT: '/sessions/nextGen/:sessionId/info',
  SIM_MATERIALS: '/sim-materials',
  ENGAGEMENT: '/engagement',
  NEXTGEN_SCENARIOS: '/nextGenScenarios',
  INVITE_TEAM_LINK: '/teamInviteLink/team/:teamId',
  PRACTICE_SESSION: '/practice-session',
  LEARNER_MATERIALS_SCENARIO_INFO: '/learner-materials/scenario',
  LEARNER_MATERIALS_SCENARIO: '/learner-materials/scenario/:scenarioId',
  ON_DEMAND:'/on-demand'
};
