import * as React from 'react';
import { Redirect } from 'react-router';
import Permission from 'src/app/data/profile/interfaces/Permission';

import ClientLayouts from 'src/layouts/client';
import CommonLayouts from 'src/layouts/common';
import ITManagerLayouts from 'src/layouts/itmanager';
import DevAdminLayouts from 'src/layouts/devAdmin';
import MursionLayouts from 'src/layouts/mursion';

import IRouteConfig from 'src/routing/IRouteConfig';
import ROUTE_PATHS from './paths';

import EULAPage from 'src/layouts/common/EULA/EULAPage';
import OAuth from 'src/layouts/common/OAuth';
import SsoLink from 'src/layouts/common/SsoLink';
import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';
import navbarIcons from 'src/layouts/common/NavigationBar/navbarIcons';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import SimulationScheduling from 'src/layouts/common/SimulationScheduling';
import EmailVerificationPage from 'src/components/EmailVerificationPage/EmailVerificationPage';
import SurveyResponse from 'src/layouts/unauthorized/SurveyResponse/SurveyResponse';
import InstructorScenarioTeamMapping from 'src/layouts/client/InstructorScenarioTeamMapping';
import DashboardReportRoute from 'src/layouts/common/DashboardReport';
import SimAttendanceV2 from 'src/layouts/common/SimAttendanceV2/SimAttendanceV2';

// unauthorized root
const Login = LoadChunk(() => import('src/layouts/unauthorized/Login'));
const PublicSession = LoadChunk(() => import('src/layouts/unauthorized/PublicSession'));
const AssignPassword = LoadChunk(() => import('src/layouts/unauthorized/AssignPassword'));
const RestorePassword = LoadChunk(() => import('src/layouts/unauthorized/RestorePassword'));
const ChangePasswordSuccess = LoadChunk(() => import('src/layouts/unauthorized/ChangePasswordSuccess'));


const NOT_FOUND_ROUTE: IRouteConfig = {
  routeProps: {
    component: () => <Redirect to={ROUTE_PATHS.CALENDAR}/>,
  },
  showInNavMenu: false,
};

const SIM_NOT_FOUND_ROUTE: IRouteConfig = {
  routeProps: {
    component: () => <Redirect to={ROUTE_PATHS.DASHBOARD}/>,
  },
  showInNavMenu: false,
};

export const GENERIC_ROUTES: IRouteConfig[] = [
  {
    routeProps: {
      component: SurveyResponse,
      path: ROUTE_PATHS.SURVEY_RESPONSE,
    },
    showInNavMenu: false,
  }
];

export const COMMON_ROUTES: IRouteConfig[] = [
  {
    routeProps: {
      component: CommonLayouts.FillProfile,
      exact: true,
      path: ROUTE_PATHS.EDIT_PROFILE,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: Login,
      path: ROUTE_PATHS.LOGIN,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Tutorial',
    routeProps: {
      component: CommonLayouts.Tutorial,
      path: ROUTE_PATHS.TUTORIAL,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Eula',
    routeProps: {
      component: () => <EULAPage/>,
      path: ROUTE_PATHS.EULA,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Auth',
    routeProps: {
      component: () => <OAuth/>,
      path: ROUTE_PATHS.OAUTH,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: () => <SsoLink/>,
      path: ROUTE_PATHS.SSO_LINK,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: () => <SimulationScheduling/>,
      path: ROUTE_PATHS.SIMULATION_SCHEDULING,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: () => <SimulationScheduling/>,
      path: ROUTE_PATHS.RESCHEDULING,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: () => <DashboardReportRoute/>,
      path: ROUTE_PATHS.DASHBOARD_REPORT,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.NextGenLearnerSessionReportLayout,
      path: ROUTE_PATHS.NEXT_GEN_LEARNER_SESSION_REPORT,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.ChangePassword,
      path: ROUTE_PATHS.CHANGE_PASSWORD,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO_INFO,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.SimMaterials,
      path: ROUTE_PATHS.SIM_MATERIALS,
    },
    showInNavMenu: false,
  },
  ...GENERIC_ROUTES
];

export const PUBLIC_SESSION_ROUTES: IRouteConfig[] = [
  {
    routeProps: {
      component: PublicSession,
      path: ROUTE_PATHS.PUBLIC_SESSION,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: EmailVerificationPage,
      path: ROUTE_PATHS.EMAIL_VERIFICATION,
    },
    showInNavMenu: false,
  },
  ...GENERIC_ROUTES
];

export const LOGIN_ROUTES: IRouteConfig[] = [
  {
    routeProps: {
      component: Login,
      path: ROUTE_PATHS.LOGIN,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: RestorePassword,
      path: ROUTE_PATHS.PASSWORD_RESTORE,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: AssignPassword,
      path: ROUTE_PATHS.ASSIGN_PASSWORD,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Tutorial',
    routeProps: {
      component: CommonLayouts.Tutorial,
      path: ROUTE_PATHS.TUTORIAL,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: ChangePasswordSuccess,
      path: ROUTE_PATHS.CHANGE_PASSWORD_SUCCESS,
    },
    showInNavMenu: false,
  },
];

export const SUPPORT_ADMIN_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Companies',
    routeProps: {
      component: ITManagerLayouts.Companies,
      path: ROUTE_PATHS.COMPANIES,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    displayName: 'MursionPortal.SessionFlow',
    routeProps: {
      component: ITManagerLayouts.Sessions,
      path: ROUTE_PATHS.SESSIONS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    routeProps: {
      component: () => <Redirect to={ROUTE_PATHS.DASHBOARD}/>,
    },
    showInNavMenu: false,
  }
];

export const BUSINESS_ADMIN_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Companies',
    routeProps: {
      component: ITManagerLayouts.Companies,
      path: ROUTE_PATHS.COMPANIES,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    routeProps: {
      component: () => <Redirect to={ROUTE_PATHS.DASHBOARD}/>,
    },
    showInNavMenu: false,
  }
];

export const DEV_ADMIN_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Companies',
    routeProps: {
      component: ITManagerLayouts.Companies,
      path: ROUTE_PATHS.COMPANIES,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    displayName: 'MursionPortal.SessionFlow',
    routeProps: {
      component: ITManagerLayouts.Sessions,
      path: ROUTE_PATHS.SESSIONS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.SessionReport',
    routeProps: {
      component: DevAdminLayouts.SessionsReportRoute,
      path: ROUTE_PATHS.SESSION_REPORT,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Monitoring',
    routeProps: {
      component: DevAdminLayouts.MonitoringRoute,
      path: ROUTE_PATHS.MONITORING,
    },
    showInNavMenu: true,
  },
  {
    routeProps: {
      component: () => <Redirect to={ROUTE_PATHS.DASHBOARD}/>,
    },
    showInNavMenu: false,
  }
];

export const SUPER_USER_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Companies',
    routeProps: {
      component: ITManagerLayouts.Companies,
      path: ROUTE_PATHS.COMPANIES,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    displayName: 'MursionPortal.SessionFlow',
    routeProps: {
      component: ITManagerLayouts.Sessions,
      path: ROUTE_PATHS.SESSIONS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.AssetSync',
    routeProps: {
      component: ITManagerLayouts.AssetSync,
      path: ROUTE_PATHS.ASSET_SYNC,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.projectsIcon,
  },
  {
    displayName: 'MursionPortal.Users',
    routeProps: {
      component: ITManagerLayouts.GlobalUsers,
      path: ROUTE_PATHS.USERS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.usersIcon,
  },
  {
    displayName: 'MursionPortal.TechRestrictions',
    routeProps: {
      component: ITManagerLayouts.TechRestrictions,
      path: ROUTE_PATHS.TECH_RESTRICTIONS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    routeProps: {
      component: () => <Redirect to={ROUTE_PATHS.COMPANIES}/>,
    },
    showInNavMenu: false,
  }
];

function getScenarioBankRoute(showInNavMenu: boolean): IRouteConfig {
  return {
    displayName: 'Settings.ScenarioBank',
    routeProps: {
      component: MursionLayouts.ScenarioBank,
      path: ROUTE_PATHS.SCENARIO_BANK,
    },
    showInNavMenu,
    navMenuIcon: navbarIcons.companyIcon,
  };
}

function getSimDesignerRoutes(showInNavMenu: boolean): IRouteConfig[] {
return [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Clients',
    permission: Permission.CLIENTS_INFO_READ,
    routeProps: {
      component: MursionLayouts.Clients,
      path: ROUTE_PATHS.CLIENTS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.clientsIcon,
  },
  getScenarioBankRoute(showInNavMenu),
  {
    displayName: 'MursionPortal.ArtDesigner',
    permission: Permission.ART_DESIGNER_READ,
    routeProps: {
      component: CommonLayouts.ArtDesignerPage,
      path: ROUTE_PATHS.ART_DESIGNER,
    },
    showInNavMenu: true,
  },
  {
    displayName: 'MursionPortal.Settings',
    routeProps: {
      component: MursionLayouts.ProviderSettings,
      path: ROUTE_PATHS.SETTINGS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.companyIcon,
  },
  {
    routeProps: {
      component: () => <Redirect to={ROUTE_PATHS.CLIENTS}/>,
    },
    showInNavMenu: false,
  }
];
}

// Common Provider Users
const PROVIDER_COMMON_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.AppDownload',
    routeProps: {
      component: CommonLayouts.Downloads,
      path: ROUTE_PATHS.DOWNLOADS,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Calendar',
    routeProps: {
      component: MursionLayouts.CompanyCalendar,
      path: ROUTE_PATHS.CALENDAR,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    displayName: 'MursionPortal.Clients',
    permission: Permission.CLIENTS_INFO_READ,
    routeProps: {
      component: MursionLayouts.Clients,
      path: ROUTE_PATHS.CLIENTS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.clientsIcon,
  },
  {
    displayName: 'MursionPortal.SessionRecording',
    permission: Permission.SESSIONS_INFO_READ,
    routeProps: {
      component: CommonLayouts.SessionReportContainer,
      path: ROUTE_PATHS.SESSIONS,
    },
    showInNavMenu: false,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.Trainings',
    permission: Permission.TRAINING_SESSIONS_READ,
    routeProps: {
      component: MursionLayouts.Trainings,
      path: ROUTE_PATHS.TRAININGS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
];

// Other Common Provider Users
const PROVIDER_OTHER_COMMON_ROUTES: IRouteConfig[] = [
  {
    displayName: 'MursionPortal.Settings',
    routeProps: {
      component: MursionLayouts.ProviderSettings,
      path: ROUTE_PATHS.SETTINGS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.companyIcon,
  },
  {
    routeProps: {
      component: CommonLayouts.ClientSurveyPage,
      path: ROUTE_PATHS.SURVEY,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LaunchSimulation,
      path: ROUTE_PATHS.OPEN,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: () => <SimAttendanceV2/>,
      path: ROUTE_PATHS.MARK_ATTENDANCE,
    },
    showInNavMenu: false,
  },
];

// Routes Config for Provider Users
export function getProviderRoutes(showInNavMenu: boolean): IRouteConfig[] {
  return [
  ...COMMON_ROUTES,
  ...PROVIDER_COMMON_ROUTES,
  {
    displayName: 'MursionPortal.Requests',
    permission: Permission.SESSIONS_INFO_READ,
    routeProps: {
      component: MursionLayouts.Requests,
      path: ROUTE_PATHS.REQUESTS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.Users',
    routeProps: {
      component: MursionLayouts.CompanyUsers,
      path: ROUTE_PATHS.USERS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.usersIcon,
  },
  getScenarioBankRoute(showInNavMenu),
  ...PROVIDER_OTHER_COMMON_ROUTES
];
}

export function getOpsRoutes(showInNavMenu: boolean): IRouteConfig[] {
  const providerRoutes: IRouteConfig[] = getProviderRoutes(showInNavMenu);
  return [
    ...providerRoutes,
    {
      displayName: 'MursionPortal.Restriction',
      routeProps: {
        component: ITManagerLayouts.TechRestrictions,
        path: ROUTE_PATHS.TECH_RESTRICTIONS,
      },
      showInNavMenu: true,
      navMenuIcon: navbarIcons.dashboardIcon,
    },
  ];
}

// Routes Config for Clients Users
export const CLIENT_ROUTES: IRouteConfig[] = [
  ...COMMON_ROUTES,
  {
    displayName: 'MursionPortal.AppDownload',
    routeProps: {
      component: CommonLayouts.Downloads,
      path: ROUTE_PATHS.DOWNLOADS,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.CommunicationKey,
      path: `${ROUTE_PATHS.ENGAGEMENT}/communication-key`,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.CustomizeEmailSettings,
      path: `${ROUTE_PATHS.ENGAGEMENT}/customize-email-settings`,
    },
    showInNavMenu: false,
  },
  {
    displayName: 'MursionPortal.Tab.Engagement',
    routeProps: {
      component: CommonLayouts.Engagement,
      path: `${ROUTE_PATHS.ENGAGEMENT}`,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Dashboard',
    routeProps: {
      component: CommonLayouts.Dashboard,
      path: ROUTE_PATHS.DASHBOARD,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.dashboardIcon,
  },
  {
    displayName: 'MursionPortal.Calendar',
    routeProps: {
      component: ClientLayouts.ClientCalendar,
      path: ROUTE_PATHS.CALENDAR,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.calendarIcon,
  },
  {
    displayName: 'MursionPortal.Projects',
    permission: Permission.PROJECTS_INFO_READ,
    routeProps: {
      component: ClientLayouts.Projects,
      path: ROUTE_PATHS.PROJECTS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.projectsIcon,
  },
  {
    displayName: 'MursionPortal.Teams',
    permission: Permission.TEAMS_INFO_READ,
    routeProps: {
      component: ClientLayouts.Teams,
      path: ROUTE_PATHS.TEAMS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.teamsIcon,
  },
  {
    displayName: 'MursionPortal.SessionRecording',
    permission: Permission.SESSIONS_INFO_READ,
    routeProps: {
      component: CommonLayouts.SessionReportContainer,
      path: ROUTE_PATHS.SESSIONS,
    },
    showInNavMenu: false,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.Reports',
    permission: [
      Permission.SESSION_REPORT_READ
    ],
    routeProps: {
      component: ClientLayouts.Reports,
      path: ROUTE_PATHS.REPORTS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.sessionsIcon,
  },
  {
    displayName: 'MursionPortal.Users',
    permission: [
      Permission.CLIENTS_INFO_WRITE, // B
      Permission.CLIENTS_LEARNERS_INFO_WRITE // F
    ],
    routeProps: {
      component: ClientLayouts.Users,
      path: ROUTE_PATHS.USERS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.usersIcon,
  },
  {
    displayName: 'MursionPortal.Settings',
    permission: [
      Permission.SSO_SCHEMAS_READ,
    ],
    routeProps: {
      component: ClientLayouts.ClientSettings,
      path: ROUTE_PATHS.SETTINGS,
    },
    showInNavMenu: true,
    navMenuIcon: navbarIcons.companyIcon,
  },
  {
    routeProps: {
      component: CommonLayouts.LaunchSimulation,
      path: ROUTE_PATHS.OPEN,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.ClientSurveyPage,
      path: ROUTE_PATHS.SURVEY,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO_INFO,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.LearnerMaterials,
      path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO,
    },
    showInNavMenu: false,
  },
  {
    routeProps: {
      component: CommonLayouts.InviteToSchedule,
      path: `${ROUTE_PATHS.ENGAGEMENT}/invite-to-schedule/team`,
    },
    showInNavMenu: false,
  },
];

const INSTRUCTOR_MAPPING = [
  {
    routeProps: {
      component: () => <InstructorScenarioTeamMapping/>,
      path: ROUTE_PATHS.INSTRUCTOR_MAPPING,
    },
    showInNavMenu: false,
  },
];

const FACILITATOR_REDIRECT_ROUTE: IRouteConfig = {
  routeProps: {
    component: () => <Redirect to={ROUTE_PATHS.DASHBOARD} />,
  },
  showInNavMenu: false,
};

function getLearnerRedirectRoute(path: string): IRouteConfig {
  return  {
    routeProps: {
      component: () => <Redirect to={path} />,
    },
    showInNavMenu: false,
  };
}

function getClientRoutes(show: boolean | undefined): IRouteConfig[] {
  return  [
    ...COMMON_ROUTES,
    {
      displayName: 'MursionPortal.AppDownload',
      routeProps: {
        component: CommonLayouts.Downloads,
        path: ROUTE_PATHS.DOWNLOADS,
      },
      showInNavMenu: false,
    },
    {
      displayName: 'MursionPortal.Tab.Onboarding',
      routeProps: {
        component: CommonLayouts.LearnerOnboardingPage,
        path: ROUTE_PATHS.ONBOARDING,
      },
      showInNavMenu: show as boolean,
    },
    {
      displayName: 'MursionPortal.Dashboard',
      routeProps: {
        component: CommonLayouts.Dashboard,
        path: ROUTE_PATHS.DASHBOARD,
      },
      showInNavMenu: true,
      navMenuIcon: navbarIcons.dashboardIcon,
    },
    {
      displayName: 'MursionPortal.Projects',
      permission: Permission.PROJECTS_INFO_READ,
      routeProps: {
        component: ClientLayouts.Projects,
        path: ROUTE_PATHS.PROJECTS,
      },
      showInNavMenu: true,
      navMenuIcon: navbarIcons.projectsIcon,
    },
    {
      displayName: 'MursionPortal.Reports',
      permission: [
        Permission.SESSION_REPORT_READ
      ],
      routeProps: {
        component: ClientLayouts.Reports,
        path: ROUTE_PATHS.REPORTS,
      },
      showInNavMenu: false,
      navMenuIcon: navbarIcons.sessionsIcon,
    },
    {
      displayName: 'MursionPortal.Settings',
      permission: [
        Permission.SSO_SCHEMAS_READ,
      ],
      routeProps: {
        component: ClientLayouts.ClientSettings,
        path: ROUTE_PATHS.SETTINGS,
      },
      showInNavMenu: true,
      navMenuIcon: navbarIcons.companyIcon,
    },
    {
      routeProps: {
        component: CommonLayouts.LaunchSimulation,
        path: ROUTE_PATHS.OPEN,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.ClientSurveyPage,
        path: ROUTE_PATHS.SURVEY,
      },
      showInNavMenu: false,
    },
    {
      displayName: 'MursionPortal.SessionRecording',
      permission: Permission.SESSIONS_INFO_READ,
      routeProps: {
        component: CommonLayouts.SessionReportContainer,
        path: ROUTE_PATHS.SESSIONS,
      },
      showInNavMenu: false,
      navMenuIcon: navbarIcons.sessionsIcon,
    },
    {
      routeProps: {
        component: CommonLayouts.JoinNow,
        path: ROUTE_PATHS.JOIN_NOW,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.LearnerMaterials,
        path: ROUTE_PATHS.LEARNER_MATERIALS,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.LearnerMaterials,
        path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO_INFO,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.LearnerMaterials,
        path: ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.InviteTeamLink,
        path: ROUTE_PATHS.INVITE_TEAM_LINK,
      },
      showInNavMenu: false,
    },
  ];
}


// Routes Config for Sim specialist Users
export function getSimSpecialistRoutes(showInNavMenu: boolean): IRouteConfig[] {
  return [
    ...COMMON_ROUTES,
    ...PROVIDER_COMMON_ROUTES,
    {
      displayName: 'MursionPortal.Users',
      routeProps: {
        component: MursionLayouts.CompanyUsers,
        path: ROUTE_PATHS.USERS,
      },
      showInNavMenu: true,
      navMenuIcon: navbarIcons.usersIcon,
    },
    {
      routeProps: {
        component: CommonLayouts.SimMaterials,
        path: ROUTE_PATHS.SIM_MATERIALS,
      },
      showInNavMenu: false,
    },
    {
      routeProps: {
        component: CommonLayouts.PracticeSession,
        path: ROUTE_PATHS.PRACTICE_SESSION,
      },
      showInNavMenu: false,
    },
    getScenarioBankRoute(showInNavMenu),
    ...PROVIDER_OTHER_COMMON_ROUTES
  ];
}

export const getRoutesConfig = (roleId: RoleID, routesData: {path: string, isOnboardingTabEnable: boolean | undefined}, nextGenScenario: boolean) => {
  switch (roleId) {
    case RoleID.IT_MANAGER:
      return SUPER_USER_ROUTES;

    case RoleID.BUSINESS_ADMIN:
      return BUSINESS_ADMIN_ROUTES;
    case RoleID.DEV_ADMIN:
      return DEV_ADMIN_ROUTES;

    case RoleID.SUPPORT_ADMIN:
      return SUPPORT_ADMIN_ROUTES;

    case RoleID.OPERATIONS:
      return [...getOpsRoutes(nextGenScenario), NOT_FOUND_ROUTE];
    case RoleID.PROF_SERVICE:
      return [...getProviderRoutes(nextGenScenario), NOT_FOUND_ROUTE];
    case RoleID.SIM_DESIGNER:
      return [...getSimDesignerRoutes(nextGenScenario)];
    case RoleID.ACC_MANAGER:
      return [...getProviderRoutes(nextGenScenario), NOT_FOUND_ROUTE];
    case RoleID.SIM_SPECIALIST:
      return [...getSimSpecialistRoutes(nextGenScenario), SIM_NOT_FOUND_ROUTE];
    case RoleID.BUYER:
      return [...CLIENT_ROUTES, ...INSTRUCTOR_MAPPING, NOT_FOUND_ROUTE];
    case RoleID.FACILITATOR:
      return [...CLIENT_ROUTES, ...INSTRUCTOR_MAPPING, FACILITATOR_REDIRECT_ROUTE, NOT_FOUND_ROUTE];
    case RoleID.LEARNER:
      return [...getClientRoutes(routesData.isOnboardingTabEnable), getLearnerRedirectRoute(routesData.path), NOT_FOUND_ROUTE];
    default:
      return null;
  }
};